@import '../../assets/Global.scss';

.site-header {
    display: flex;
    padding: 8px 32px;
    background-color: $main-grey;
    position: relative;
    background-image: linear-gradient(45deg, #162D6B, #171717);
    justify-content: space-between;
    
    @media only screen and (max-width: $breakpoint-mobile) {
        padding: 8px 16px;
    }

    .universal-back {
        display: none;
    }

    .left-content {
        display: flex;
        column-gap: 8px;
        align-items: center;

        &:hover {
            text-decoration: none;
        }

        .logo {
            height: 40px;
        }

        .title {
            font-size: 24px;
            font-weight: 400;
            color: white;

            @media screen and (max-width: $breakpoint-mobile) {
                font-size: 18px;
            }
        }
    }

    .site-menu-btn {
        position: absolute;
        right: 32px;
        top: 50%;
        transform: translateY(-50%);
    }

    .menu-nav {
        display: none;
        position: absolute;
        right: 32px;
        top: 56px;
        background-color: $secondary-grey;
        
        &.expanded {
            display: block;
        }

        .site-menu-container {
            flex-direction: column;
            display: flex;
            row-gap: 16px;
            padding: 16px;
        }
    }

    .dvd-mode {
        background-color: transparent;
        border-top: 2px solid #565656;
        border-left: 2px solid #565656;
        border-bottom: 2px solid black;
        border-right: 2px solid black;
        width: fit-content;
        padding: 1px 6px;

        &:focus, &:focus-within {
            background-color: transparent;
        }

        .logo {
            width: 32px; 
        }
    }
}