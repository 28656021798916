// COLORS
$main-grey: #D3CFC8;
$secondary-grey: #BCB8B1;
$grey-border: #00000030;
$white-border: rgba(#fff, .75);

// BREAKPOINTS
$breakpoint-mobile: 600px;

// VARIABLES 
$search-height: 76px;
$site-nav-height: 41px;
$site-result-bar-height: 40px;

$site-header-height: 52px;
$site-header-height-m: 43px;

// MIXINS
@mixin visuallyHidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}