@import '../../assets/Global.scss';

.main-search-container {
    padding: 16px;
    display: flex;
    justify-content: center;
    background-color: $main-grey;
    border-bottom: 2px solid rgba(0, 0, 0, .3);

    @media screen and (max-width: $breakpoint-mobile) {
        padding: 16px;
        justify-content: flex-start;
    }

    .search {
        width: 500px;

        @media screen and (max-width: $breakpoint-mobile) {
            width: 100%;
        }
    }

    .clear-btn {
        border-radius: 0;
        border-top: 1px solid $white-border;
        border-left: 1px solid $white-border;
        border-bottom: 1px solid $grey-border;
        border-right: 1px solid $grey-border;
        padding: 0px 16px;
        background-color: $main-grey;
        color: black;
        user-select: none;

        &:hover {
            background-color: $secondary-grey;
        }

        &:focus {
            background-color: $main-grey;
        }

        &:disabled {
            pointer-events: none;
            color: rgba(16, 16, 16, 0.3);
        }
    }
}