@import './assets/Global.scss';

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Arial', 'Roboto', 'Segoe UI', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Set core body defaults */
body {
  min-height: 100dvh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow: hidden; 
}

/* Remove list styles on ul, ol elements with a class attribute */
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/**
 * Remove the gray background on active links in IE 10.
 */
a {
  color: black;
  text-decoration: none;
  background-color: transparent;

  &:visited {
      color: black;
  }

  &:hover {
      // text-decoration: underline;
  }
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
  border-style: none;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  // margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  cursor: pointer;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}