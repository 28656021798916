@import '../../assets/Global.scss';

.bar-nav {
    .site-menu-container {
        display: flex;
        background-color: $secondary-grey;
        justify-content: center;
        
        .site-menu-item {
            flex-basis: 100%;
            border-top: 1px solid transparent;

            &:first-of-type {
                border-left: none;
                border-right: 1px solid $grey-border;
            }

            &:last-of-type {
                border-right: none;
                border-left: 1px solid $grey-border;
            }

            &.selected {
                background-color: $main-grey;
                border-top: 1px solid $white-border;

                &:first-of-type {
                    border-left: none;
                    border-right: 1px solid $white-border;
                }

                &:last-of-type {
                    border-right: none;
                    border-left: 1px solid $white-border;
                }
            }

            button {
                padding: 8px 32px;
                border: none;
                background: transparent;
                width: 100%;
                color: black;

                @media screen and (max-width: $breakpoint-mobile) {
                    padding: 8px 16px;
                }
            }
        }
    }
}

.results-bar {
    background-color: $main-grey;
    padding: 8px 44px;

    @media screen and (max-width: $breakpoint-mobile) {
        padding: 8px;
    }
}