@import '../../assets/Global.scss';

.dvd-logo {
    position: absolute;
    visibility: hidden;
    width: 250px;
    height: 110px;

    @media screen and (max-width: $breakpoint-mobile) {
      width: 150px;
      height: 66px;
    }
  }