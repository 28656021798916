@import '../../assets/Global.scss';

.placard-container {
    padding: 16px;
    border-radius: 4px;
    font-weight: bold;
    border: 1px solid $secondary-grey;
    border-radius: 0;
    position: relative;
    
    .title {
        .wish-list {
            position: absolute;
            bottom: 4px;
            right: 4px;
            font-size: 12px;
        }
    }

    .disney {
        width: 45px;
        position: absolute;
        top: 4px;
        right: 4px;
    }
}