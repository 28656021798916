@import '../../assets/Global.scss';

.dvd-list-container {
    box-shadow: 0 0 4px #000000 inset;
    border-right: 8px solid $main-grey;
    border-left: 8px solid $main-grey;
    border-bottom: 8px solid $main-grey;
    padding: 4px;
    height: 100%;
    flex-grow: 1;

    .dvd-list {
        display: flex;
        gap: 16px;
        padding: 32px;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        position: relative;
    
        @media screen and (max-width: $breakpoint-mobile) {
            padding: 16px;
        }
    }

    .no-results {
        padding: 32px;

        @media screen and (max-width: $breakpoint-mobile) {
            padding: 16px;
        }
    }
}
