@import '../../assets/Global.scss';

.main {
    display: flex;
    flex-direction: column;
    height: calc(100vh - $site-header-height);

    &.loading {
        border-left: 1px solid $main-grey;
        border-right: 1px solid $main-grey;
        position: relative;

        @media screen and (max-width: $breakpoint-mobile) {
            border: none;
        }
    }

    @media screen and (max-width: $breakpoint-mobile) {
        height: calc(100dvh - $site-header-height-m);
    }

    .content-container {
        display: flex;
        height: calc(100% - $search-height - $site-nav-height - $site-result-bar-height);

        @media screen and (max-width: $breakpoint-mobile) {
            height: calc(100% - $search-height - $site-nav-height - $site-result-bar-height);
        }
    }
}