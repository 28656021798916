@import '../../assets/Global.scss';

.search-input-label {
    &.hidden {
        @include visuallyHidden();       
    }
}

.search-input {
    width: 100%;
    padding: 8px;
    border-radius: 0;
    border-width: 1px;
}